.scale-in-center {
  -webkit-animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    1s both;
  animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

@media (max-width: 800px) {
  .form-on-background {
    text-align: center;
    margin-left: calc(50vw - 125px);
    margin-top: 100px;
    position: absolute;
    height: 100px;
    width: 250px;
    border-radius: 20px;
  }

  .title-text {
    font-family: "Hoefler Text", Georgia, "Times New Roman", serif;
    font-weight: bold;
    /* -webkit-text-stroke: 0.2px gainsboro; */
    font-size: 20px;

    margin: 0px;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (min-width: 801px) {
  .form-on-background {
    text-align: center;
    margin-left: calc(50vw - 250px);
    margin-top: 100px;
    position: absolute;
    height: 520px;
    width: 500px;
    border-radius: 20px;
  }

  .title-text {
    font-family: "Hoefler Text", Georgia, "Times New Roman", serif;
    font-weight: bold;
    /* -webkit-text-stroke: 0.2px gainsboro; */
    font-size: 41px;
    letter-spacing: 0.2em;
    line-height: 1.1em;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
  }
}


.split-bar {
  width: 94%;
  margin-left: 2%;
  margin-top: 55px;
  border-radius: 5px;
  height: 12px;
  background: linear-gradient(234deg, rgba(0,0,0,0.8102591378348214) 0%, rgba(122,126,53,1) 25%, rgba(233,232,96,1) 59%, rgba(251,252,205,1) 100%);
}


.btn-grad {background-image:  linear-gradient(234deg, rgba(0,0,0,0.8102591378348214) 0%, rgba(122,126,53,1) 25%, rgb(219, 219, 69) 59%, rgb(238, 240, 121) 100%);}
.btn-grad {
   margin: auto;
   width: 120px;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   font-size: 17px;
   transition: 0.5s;
   background-size: 200% auto;
   color: black;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   text-decoration: none;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


@media (max-width: 800px) {
  .flex-width {
    width: 100%;
  }
}

@media (min-width: 801px) {
  .flex-width {
    width: 50%;
  }
}

/* Project and Resume Pictures */
@media (max-width: 800px) {
  .flex-project-picture {
    width: 150px;
  }
  .flex-resume-picture {
    width: 180px;
  }
}
@media (max-width: 1200px) AND (min-width: 801px) {
  .flex-project-picture {
    width: 320px;
  }
  .flex-resume-picture {
    width: 280px;
  }
}

@media (min-width: 1201px) {
  .flex-project-picture {
    width: 450px;
  }
  .flex-resume-picture {
    width: 370px;
  }
}


.background {
  text-align: center;
  position: absolute;
}

.icon-with-text{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

