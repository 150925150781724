@media (max-width: 800px) {
  .icon-right-space {
    margin-right: 20px;
  }
}

@media (min-width: 801px) {
  .icon-right-space {
    margin-right: 120px;
  }
}
