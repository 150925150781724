@media (max-width: 800px) {
  .information-form {
    margin-left: 5%;
    margin-bottom: 50px;
    width: 90%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 4px 4px 12px darkgray;
    text-align: center;
    align-content: center;
  }
  .body-text {
    font-size: 14px;
  }
}

@media (min-width: 801px) {
  .information-form {
    margin-left: 25%;
    margin-bottom: 50px;
    width: 50%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 4px 4px 12px darkgray;
    text-align: center;
    align-content: center;
  }
  .body-text {
    font-size: 24px;
  }
}

.icon-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.icon-color{
    color: rgb(196, 196, 11)
}

